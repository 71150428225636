<template>
  <div class="tw-py-2">
    <div>
      <h2 class="tw-text-xl tw-mb-1">Payment Methods</h2>
      <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
        <div v-for="paymentMethod in paymentMethods"
            :key="paymentMethod.id"
            class="tw-bg-gray-100 tw-rounded-md tw-shadow">
          <div class="tw-flex tw-flex-col">
            <div class="tw-flex-row tw-flex tw-justify-between tw-bg-gray-400 tw-rounded-t-md tw-px-2 tw-py-2">
              <h3 class="tw-text-lg tw-font-bold tw-leading-7">{{ paymentMethod.name }}</h3>
            </div>
            
            <div class="tw-p-2">
              <div class="flex justify-between">
                <span class="tw-text-gray-600">Created:</span>
                <span class="tw-font-medium">{{ paymentMethod.created_at.format('MM/DD/YYYY') }}</span>
              </div>

              <div class="flex justify-between">
                <span class="tw-text-gray-600">Account Number:</span>
                <span class="tw-font-medium">x{{ paymentMethod.metadata.last_4 }}</span>
              </div>

              <div class="flex justify-between">
                <span class="tw-text-gray-600">Tag:</span>
                <span class="tw-font-medium">{{ paymentMethod.tag === 'primary' ? 'Primary' : 'Secondary' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-mt-4 tw-px-2 tw-py-2 tw-max-w-xs tw-bg-gray-100 tw-rounded-md tw-shadow" v-if="paymentMethodEditable">
      <h2 class="tw-text-lg tw-mb-1">New Payment Method</h2>

      <ks-input-field type="text" v-model="newAccountName" label="Name" :$v="$v.newAccountName"/>
      <button class="btn btn--has-vertical-margins btn-primary bank-button"
        type="button" :api_login_id="api_login_id" method="token" version_number="1.0" save_token="true"
        callback="handleForteModalCloseInVue" :signature="signature" :utc_time="utc_time" hash_method="md5"
        allowed_methods="echeck" :billing_email_address="billing_email_address">
        Add Bank Account
      </button>
    </div>
  </div>
</template>

<script>
  import { getPaymentMethodsStoreFor } from 'Stores/PaymentMethodsStore'
  import { WindowVariables } from 'Utils'
  import KsIconButton from 'Components/KsIconButton'
  import svgicon from 'vue-svgicon'
  import KsInputField from 'Components/form/KsInputField'
  import KsButton from 'Components/form/KsButton'
  import VbaPolicy from 'Models/policies/VbaPolicy'
  import { required } from 'vuelidate/lib/validators'
  import { sortBy } from 'lodash'

  export default {
    name: 'PaymentMethodsManager',
    components: {
      KsIconButton,
      svgicon,
      KsInputField,
      KsButton,
    },
    data () {
      return {
        newAccountName: "",
        paymentMethodsStore: getPaymentMethodsStoreFor({
          paymentable_type: this.paymentable_type,
          paymentable_id: this.paymentable_id
        }, { fetchAll: true }),
        vbaPolicy: new VbaPolicy({
            id: parseInt(WindowVariables.agencyId),
            permissionsAgencyId: parseInt(WindowVariables.agencyId),
            permissionsBusinessId: null
          })
      }
    },
    fromMobx: {
      paymentMethods () {
        return sortBy(this.paymentMethodsStore.records, 'created_at')
      },
    },
    computed: {
      paymentMethodEditable () {
        return this.vbaPolicy && this.vbaPolicy.canUpdateVbaManagers
      }
    },
    validations: {
      newAccountName: {
        required,
      }
    },
    methods: {
      handleModalClose(forteOutput) {
        const data = JSON.parse(forteOutput.data)
        console.log('handleModalClose', forteOutput, data)
        if (data.event === 'success') {
          this.paymentMethodsStore.create({
          name: this.newAccountName,
          tag: 'secondary',
          paymentable_type: this.paymentable_type,
          paymentable_id: this.paymentable_id,
          paymethod_token: data.paymethod_token,
          customer_token: data.customer_token,
          signature: data.signature,
          utc_time: data.utc_time,
          provider: 'Forte',
          }).then(pm => { 
            console.log('pm', pm)
          })
        }
      }
    },
    created () {
      window.handleForteModalCloseInVue = this.handleModalClose
    },
    mounted () {
      const script = document.createElement('script');
      script.id = 'fortescript';
      script.src = 'https://sandbox.forte.net/checkout/v1/js'
      script.async = true;
      script.onload = function() {
        console.log('script loaded, you can use it now.');
        // createMap();
      }
      // document.body.append(script);
    },  
    watch: {
      
    },
    props: {
      paymentable_type: {
        type: String,
        required: true,
      },
      paymentable_id: {
        type: Number,
        required: true,
      },
      api_login_id: {
        type: String,
        required: true,
      },
      utc_time: {
      },
      billing_email_address: {},
      signature: {},
    },
  }
</script>