import { WindowVariables } from 'Utils'
import { observable } from 'mobx'
import { some } from 'lodash'

// NOTE: POSSIBLE TO BRING THESE OVER FROM RUBY WITH OPAL?
const VARIANT_MINE = 'mine'
const VARIANT_AGENCY = 'agency'
const VARIANT_BUSINESS = 'business'

function generatePermissionsQueryId (recordType, record, can) {
  if (recordType !== undefined && record !== undefined && can !== undefined) {
    return `${recordType}-${record.id}-${can}`
  }
  return undefined
}

// Serves as a single point where the permissions are stored and can be accessed
// via, for now, a single queryPermissions method.
class PermissionsOverseer {
  @observable _currentPermissions = {}

  _informers = {}

  get currentPermissions () { return this._currentPermissions }
  set currentPermissions (perms) {
    // When new permissions get set, update all cached observable booleans
    // to recompute with the new permissions
    // console.log('Setting New Permissions', perms)
    this._currentPermissions = perms
    for (let key in this._informers) {
      this._informers[key].recompute()
    }
  }

  hasKey (permissionString) {
    return this._currentPermissions[permissionString]
  }

  // Find or create a new permissions informer with an observable
  // object to watch in Vue components
  queryPermissions (recordType, record, permissionString) {
    const queryId = generatePermissionsQueryId(recordType, record, permissionString)
    if (queryId === undefined) {
      console.error('Attempted to create permissions query with incomplete data:', recordType, record, permissionString)
      return undefined
    }

    if (this._informers[queryId] === undefined) {
      this._informers[queryId] = new PermissionsInformer(queryId, record, permissionString)
    }
    return this._informers[queryId]
  }
}

const OVERSEER = new PermissionsOverseer()
// OVERSEER.currentPermissions = WindowVariables.newCachedPermissions || {}
// using it directly from window.dkVariables['new_permissions_by_key'] because
//   we had bad issues with circular references when fixing another issue
OVERSEER.currentPermissions = window.dkVariables['new_permissions_by_key']
export default OVERSEER

// The PermissionsInformer serves as a wrapper class around a single permission.
// It is constructed from a "queryId", a record, and a permissionString.
// The great magic of this is that the "can" getter is observable, so anything
// looking at it will update automatically when its value changes.

// Because the PermissionsOverseer (above) is holding a reference to all of the
// PermissionsInformers that have been created, whenever the PermissionsOverseer
// receives a new set of permissions, it can call `recompute` on all of the informers
// which subsequently updates their observable getters. This creates the desired effect
// of having the UI hooked up to the informers react to permissions changes. Very cool!
export class PermissionsInformer {
  _record = undefined
  _queryId = undefined
  _permissionString = undefined

  _can = observable.box(false)

  get record () { return this._record }
  get queryId () { return this._queryId }
  get permissionString () { return this._permissionString }
  get can () { return this._can.get() }

  constructor (queryId, record, permissionString) {
    this._queryId = queryId
    this._record = record
    this._permissionString = permissionString
    this._overseer = OVERSEER
    this.recompute()
  }

  recompute () {
    const result = this._computeResult()
    this._can.set(result)
    return result
  }

  _computeResult () {
    if (WindowVariables.isSpecialtySuperAdmin) {
      return !WindowVariables.restrictedPermissionKeys.includes(this.permissionString)
    }
    const permissionInQuestion = OVERSEER.currentPermissions[this.permissionString]
    if (permissionInQuestion === undefined) {
      // console.log('returning false for permissionInQuestion being undefined', this.permissionString)
      return false
    }

    // do they have anything for this key?
    //  check each entity+variant to see if the object checks out for the agency+business||mine check
    return some(permissionInQuestion, perm => {
      return some(perm.variants, variant => {
        // console.log(perms, key, permissionInQuestion, perm, variant)
        if (variant === VARIANT_MINE) {
          return this.record.permissionsOwnedByUserId === WindowVariables.currentUserId
        } else if (variant === VARIANT_AGENCY) {
          // console.log('agency variant', object.permissionsAgencyId, perm.entity_type, perm.entity_id, object.permissions_agency_id === perm.entity_id && perm.entity_type === 'Agency')
          if (this.record.permissionsAgencyIds) {
            return this.record.permissionsAgencyIds.includes(perm.entity_id) && perm.entity_type === 'Agency'
          } else if (this.record.permissionsAgencyId) {
            return this.record.permissionsAgencyId === perm.entity_id && perm.entity_type === 'Agency'
          } else {
            return this.record.id === perm.entity_id && perm.entity_type === 'Agency'
          }
        } else if (variant === VARIANT_BUSINESS) {
          return this.record.permissionsBusinessId === perm.entity_id && perm.entity_type === 'Business'
        } else {
          return false
        }
      })
    })
  }
}

// export function getPermissionsQueryInformer () {
//   OVERSEER.queryPermissions(MODEL_NAME, this._record, 'event.accept')
// }

export const permissionStrings = {
  activityLog: {
    CREATE_ENTRY: 'activity_log.create_entry',
    CREATE_ENTRY_IN_AGENCY: 'activity_log.create_entry_in_agency',
    UPDATE_ENTRY: 'activity_log.update_entry',
    VIEW_ENTRIES: 'activity_log.view_entries',
    VIEW_ENTRIES_IN_AGENCY: 'activity_log.view_entries_in_agency',
    VIEW_ENTRIES_IN_BUSINESS: 'activity_log.view_entries_in_business',
    VIEW_INACTIVE_ENTRIES: 'activity_log.view_inactive_entries',
    VIEW_INACTIVE_TYPE_FIELDS: 'activity_log.view_inactive_type_fields',
    VIEW_INACTIVE_TYPES: 'activity_log.view_inactive_types',
    VIEW_TYPE_FIELDS: 'activity_log.view_type_fields',
    VIEW_TYPES: 'activity_log.view_types',
    VIEW_TEMPLATES: 'activity_log.view_templates',
  },
  agency: {
    VIEW_AGENCY_OFFICER_TYPES: 'agency.view_agency_officer_types',
    VIEW_DASHBOARD: 'agency.view_dashboard',
    VIEW_MY_AGENCY_OFFICER_TYPES: 'view_my_agency_officer_types',
    TOGGLE_DEFAULT_PAYROLL_TRANSFER_SETTING: 'agency.toggle_default_payroll_transfer_setting',
  },
  assignment: {
    ACCEPT: 'assignments.accept',
    APPROVE_CANCELLATION: 'assignments.approve_cancellation',
    CREATE_ACTIVITY_LOG_ENTRY: 'assignments.create_activity_log_entry',
    DENY_CANCELLATION: 'assignments.deny_cancellation',
    EDIT: 'assignments.edit',
    INDEX: 'assignments.index',
    MANAGE: 'assignments.manage',
    REQUEST_CANCEL: 'assignments.request_cancel',
    REQUEST_CANCEL_FOR_OTHER_USER: 'assignments.request_cancel_for_other_user',
    REPLACE: 'assignments.replace',
    REJECT: 'assignments.reject',
    UPDATE_STATUS: 'assignments.update_status',
    VIEW_INDEX: 'assignments.view_index',
    VIEW_CANCELLATION_FOR_OTHER_USER: 'assignments.view_cancellation_for_other_user',
    VIEW_PAYMENTS: 'assignments.view_payments',
    VIEW_PENDING: 'assignments.view_pending',
    WITHDRAW_CANCEL: 'assignments.withdraw_cancel',
    WITHDRAW_CANCEL_FOR_OTHER_USER: 'assignments.withdraw_cancel_for_other_user',
  },
  attachment: {
    CREATE: 'attachment.create',
  },
  bid: {
    VIEW: 'bid.view',
    VIEW_MINE: 'bid.view_mine',
  },
  business: {
    CREATE_ATTACHMENT: 'business.create_attachment',
    VIEW_DK_RATE: 'business.view_dk_rate',
  },
  estimate: {
    APPROVE: 'estimate.approve',
    APPROVE_IN_AGENCY: 'estimate.approve_in_agency',
    APPROVE_IN_AGENCY_WITH_NO_PAYMENT_METHOD: 'estimate.approve_in_agency_with_no_payment_method',
    APPROVE_WITHOUT_SENDING: 'estimate.approve_without_sending',
    APPROVE_WITHOUT_SENDING_IN_AGENCY: 'estimate.approve_without_sending_in_agency',
    APPROVE_IN_BUSINESS: 'estimate.approve_in_business',
    DECLINE: 'estimate.decline',
    DECLINE_IN_AGENCY: 'estimate.decline_in_agency',
    DECLINE_IN_BUSINESS: 'estimate.decline_in_business',
    CHARGE_DEPOSIT: 'estimate.charge_deposit',
    CHARGE_DEPOSIT_IN_AGENCY: 'estimate.charge_deposit_in_agency',
    CHARGE_DEPOSIT_IN_BUSINESS: 'estimate.charge_deposit_in_business',
    INDEX: 'estimate.index',
    SEND: 'estimate.send',
    SEND_IN_AGENCY: 'estimate.send_in_agency',
    VIEW_DETAILED_IN_AGENCY: 'estimate.view_detailed_in_agency',
    VIEW_IN_AGENCY: 'estimate.view_in_agency',
    VIEW_IN_BUSINESS: 'estimate.view_in_business',
    WAIVE_DEPOSIT_IN_AGENCY: 'estimate.waive_deposit_in_agency',
  },
  event: {
    ACCEPT: 'event.accept',
    ACTIVATE: 'event.activate',
    ADD_FEES: 'event.add_fees',
    APPROVE_ESTIMATE: 'event.approve_estimate',
    CANCEL: 'event.cancel',
    CANCEL_WHILE_REQUESTED: 'event.cancel_while_requested',
    CLONE: 'event.clone',
    CLOSE_BIDDING: 'event.close_bidding',
    CREATE: 'event.create',
    CREATE_APPROVAL_REQUESTS: 'event.create_approval_requests',
    CREATE_ATTACHMENT: 'event.create_attachment',
    CREATE_NOTES: 'event.create_notes',
    DECLINE_ESTIMATE: 'event.decline_estimate',
    DEFAULT_FILTER_ALL_JOBS: 'event.default_filter_all_jobs',
    DELETE_FEES: 'event.delete_fees',
    EDIT_ALCOHOL_SERVED: 'event.edit_alcohol_served',
    EDIT_BEFORE_EVENT_ESTIMATED: 'event.edit_before_event_estimated',
    EDIT_BEFORE_EVENT_INVOICED: 'event.edit_before_event_invoiced',
    EDIT_BIDDING_END_DATE: 'event.edit_bidding_end_date',
    EDIT_BIDDING_START_DATE: 'event.edit_bidding_start_date',
    EDIT_BUSINESS_ID: 'event.edit_business_id',
    EDIT_CONTACT_1_ID: 'event.edit_contact_1_id',
    EDIT_CUSTOM_INVOICE_ID: 'event.edit_custom_invoice_id',
    EDIT_DESCRIPTION: 'event.edit_description',
    EDIT_DEPOSIT_AMOUNT: 'event.edit_deposit_amount',
    EDIT_DEPOSIT_TYPE: 'event.edit_deposit_type',
    EDIT_END_DATE: 'event.edit_end_date',
    EDIT_ESTIMATED_ATTENDANCE: 'event.edit_estimated_attendance',
    EDIT_ESTIMATED_VEHICLES: 'event.edit_estimated_vehicles',
    EDIT_EVENT_CATEGORY_ID: 'event.edit_event_category_id',
    EDIT_FEES: 'event.edit_fees',
    EDIT_GATE_CODE: 'event.edit_gate_code',
    EDIT_JOB_NOTIFY_OVERRIDE_OPTION: 'event.edit_job_notify_override_option',
    EDIT_JOB_PICKUP_OVERRIDE_OPTION: 'event.edit_job_pickup_override_option',
    EDIT_LOCATION: 'event.edit_location',
    EDIT_LOCKBOX_CODE: 'event.edit_lockbox_code',
    EDIT_NAME: 'event.edit_name',
    EDIT_NUMBER_OF_OFFICERS: 'event.edit_number_of_officers',
    EDIT_OVERTIME: 'event.edit_overtime',
    EDIT_PAYMENT_METHOD_TYPE: 'event.edit_payment_method_type',
    EDIT_PERMIT: 'event.edit_permit',
    EDIT_SPECIAL_INSTRUCTIONS: 'event.edit_special_instructions',
    EDIT_START_DATE: 'event.edit_start_date',
    ESTIMATE: 'event.estimate',
    INDEX: 'event.index',
    INTERACTING_AS_AGENCY: 'event.interacting_as_agency',
    INTERACTING_AS_BUSINESS: 'event.interacting_as_business',
    INVOICE: 'event.invoice',
    MARK_DISTRIBUTED: 'event.mark_distributed',
    MARK_INVOICED: 'event.mark_invoiced',
    MARK_PAID: 'event.mark_paid',
    OPEN_BIDDING: 'event.open_bidding',
    REJECT: 'event.reject',
    REMOVE_ATTACHMENT: 'event.remove_attachment',
    REOPEN_BIDDING: 'event.reopen_bidding',
    RESPOND_TO_APPROVAL_REQUESTS: 'event.respond_to_approval_requests',
    SCHEDULE: 'event.schedule',
    SHOW_OVERVIEW_TAB: 'event.show_overview_tab',
    SELECT_MANUAL_PAYMENT_METHOD: 'event.select_manual_payment_method',
    SELECT_UNAVAILABLE_PAYMENT_METHOD: 'event.select_unavailable_payment_method',
    SKIP_BIDDING: 'event.skip_bidding',
    UNACCEPT: 'event.unaccept',
    UPDATE_FEES: 'event.update_fees',
    VIEW: 'event.view',
    VIEW_ACTIVITY_LOG: 'event.view_activity_log',
    VIEW_ALCOHOL_SERVED: 'event.view_alcohol_served',
    VIEW_APPROVAL_REQUESTS: 'event.view_approval_requests',
    VIEW_ASSIGNMENTS: 'event.can_view_assignments',
    VIEW_ASSIGNMENTS_AFTER_SCHEDULED: 'event.can_view_assignments_after_scheduled',
    VIEW_BIDDING_END_DATE: 'event.view_bidding_end_date',
    VIEW_BIDDING_START_DATE: 'event.view_bidding_start_date',
    VIEW_BUSINESS_ID: 'event.view_business_id',
    VIEW_CONTACT_1_ID: 'event.view_contact_1_id',
    VIEW_CUSTOM_INVOICE_ID: 'event.view_custom_invoice_id',
    VIEW_DEPOSIT_AMOUNT: 'event.view_deposit_amount',
    VIEW_DEPOSIT_TYPE: 'event.view_deposit_type',
    VIEW_DEPOSITS: 'event.view_deposits',
    VIEW_DESCRIPTION: 'event.view_description',
    VIEW_DISTRIBUTIONS: 'event.view_distributions',
    VIEW_END_DATE: 'event.view_end_date',
    VIEW_ESTIMATE: 'event.view_estimate',
    VIEW_ESTIMATED_ATTENDANCE: 'event.view_estimated_attendance',
    VIEW_ESTIMATED_VEHICLES: 'event.view_estimated_vehicles',
    VIEW_EVENT_CATEGORY_ID: 'event.view_event_category_id',
    VIEW_EXPENSE_CATEGORY_SUMMARY: 'event.view_expense_category_summary',
    VIEW_GATE_CODE: 'event.view_gate_code',
    VIEW_INDEX: 'event.view_index',
    VIEW_INVOICE: 'event.view_invoice',
    VIEW_JOB_FILTERS: 'event.view_job_filters',
    VIEW_JOB_STATS: 'event.can_view_job_stats',
    VIEW_KOMMANDER_PAY: 'event.view_kommander_pay',
    VIEW_LOCATION: 'event.view_location',
    VIEW_LOCKBOX_CODE: 'event.view_lockbox_code',
    VIEW_MY_ASSIGNMENTS: 'event.can_view_my_assignments',
    VIEW_NAME: 'event.view_name',
    VIEW_NOTES: 'event.view_notes',
    VIEW_NUMBER_OF_OFFICERS: 'event.view_number_of_officers',
    VIEW_OVERTIME: 'event.view_overtime',
    VIEW_PAYMENTS: 'event.view_payments',
    VIEW_PAYMENTS_AND_DISTRIBUTIONS: 'event.view_payments_and_distributions',
    VIEW_PAYMENT_METHOD_TYPE: 'event.view_payment_method_type',
    VIEW_PERMIT: 'event.view_permit',
    VIEW_ROSTER: 'event.view_roster',
    VIEW_SPECIAL_INSTRUCTIONS: 'event.view_special_instructions',
    VIEW_START_DATE: 'event.view_start_date',
    VIEW_WARNINGS: 'event.view_warnings',
    WITHDRAW_APPROVAL_REQUESTS: 'event.withdraw_approval_requests',
    BLOCK_DISTRIBUTION: 'event.block_distribution',
    UNBLOCK_DISTRIBUTION: 'event.unblock_distribution',
  },
  eventFee: {
    CREATE: 'event_fees.create',
    DELETE: 'event_fees.delete',
    UPDATE: 'event_fees.update',
    VIEW: 'event_fees.view',
  },
  general: {
    VIEW_HELP_GUIDE: 'general.view_help_guide',
  },
  job: {
    CREATE: 'jobs.create',
    INDEX: 'jobs.index',
    MANAGE_ASSIGNMENTS: 'jobs.manage_assignments',
    VIEW_ASSIGNMENTS: 'jobs.view_assignments',
    VIEW_ASSIGNMENTS_AFTER_SCHEDULED: 'jobs.view_assignments_after_scheduled',
    VIEW_INDEX: 'jobs.view_index',
    VIEW_INDEX_FOR_AGENCY: 'jobs.view_index_for_agency',
    VIEW_INDEX_FOR_OTHER_USER: 'jobs.view_index_for_other_user',
    UPDATE: 'jobs.update',
    VIEW_PAY_SCHEDULE_ESTIMATE_AGENCY: 'jobs.view_pay_schedule_estimate.agency',
    VIEW_PAY_SCHEDULE_ESTIMATE_AGGREGATE: 'jobs.view_pay_schedule_estimate.aggregate',
    VIEW_PAY_SCHEDULE_ESTIMATE_USER: 'jobs.view_pay_schedule_estimate.user',
    VIEW_STATS: 'jobs.view_stats',
  },
  paySchedule: {
    VIEW: 'pay_schedules.view',
  },
  report: {
    VIEW_ASSIGNMENTS: 'report.view_assignments',
  },
  roster: {
    VIEW_AGENCY: 'roster.view_agency',
    VIEW_EVENT: 'roster.view_event',
  },
  task: {
    INDEX: 'task.index',
    SHOW_TASK_LIST: 'task.show_task_list',
  },
  taxCenter: {
    VIEW_FOR_BUSINESS: 'tax_center.view_for_business',
    VIEW_FOR_BUSINESSES_IN_AGENCY: 'tax_center.view_for_businesses_in_agency',
    VIEW_FOR_SELF: 'tax_center.view_for_self',
    VIEW_FOR_USERS_IN_AGENCY: 'tax_center.view_for_users_in_agency',
  },
  taxDocument: {
    CREATE_FOR_SELF: 'tax_document.create_for_self',
    CREATE_FOR_USERS_IN_AGENCY: 'tax_document.create_for_users_in_agency',
    DESTROY_FOR_SELF: 'tax_document.destroy_for_self',
    DESTROY_FOR_USERS_IN_AGENCY: 'tax_document.destroy_for_users_in_agency',
    INDEX_FOR_BUSINESS: 'tax_document.index_for_business',
    INDEX_FOR_SELF: 'tax_document.index_for_self',
    INDEX_FOR_USERS_IN_AGENCY: 'tax_document.index_for_users_in_agency',
    VIEW_ATTACHED_DOCUMENT_FOR_SELF: 'tax_document.view_attached_document_for_self',
    VIEW_ATTACHED_DOCUMENT_FOR_USERS_IN_AGENCY: 'tax_document.view_attached_document_for_users_in_agency',
    VIEW_ATTACHED_DOCUMENT_FOR_USERS_WORKING_WITH_BUSINESS: 'tax_document.view_attached_document_for_users_working_for_business',
  },
  taxReport: {
    VIEW_FOR_AGENCY: 'tax_report.view_for_agency',
    VIEW_FOR_BUSINESS: 'tax_report.view_for_business',
    VIEW_FOR_SELF: 'tax_report.view_for_self',
    VIEW_FOR_USERS_IN_AGENCY: 'tax_report.view_for_users_in_agency',
  },
  user: {
    BULK_IMPORT: 'user.bulk_import',
    CREATE_ANY: 'user.create_any',
    CREATE_FOR_AGENCY: 'user.create_for_agency',
    CREATE_FOR_BUSINESS_IN_AGENCY: 'user.create_for_business_in_agency',
    CREATE_FOR_BUSINESS: 'user.create_for_business',
    CREATE_FOR_SUPER_ADMIN: 'user.create_super_admin',
    INDEX: 'user.index',
    INDEX_IN_AGENCY: 'user.index_in_agency',
    VIEW_AGENCY_INDEX: 'user.view_agency_index',
    VIEW_BUSINESS_INDEX: 'user.view_business_index',
  },
  virtualBankAccount: {
    APPROVE_DISTRIBUTION: 'vba.approve_distribution',
    INDEX: 'vba.index',
    INITIATE_DISTRIBUTION: 'vba.initiate_distribution',
    SHOW: 'vba.show',
    SHOW_FOR_OTHER_USER: 'vba.show_for_other_user',
    TOGGLE_PAYROLL_TRANSFER_SETTING: 'vba.toggle_payroll_transfer_setting',
    UPDATE: 'vba.update',
    UPDATE_FOR_AGENCY: 'vba.update_for_agency',
    UPDATE_FOR_OTHER_USER: 'vba.update_for_other_user',
    VIEW_DEBUG_PAGES: 'vba.view_debug_pages',
    UPDATE_VBA_MANAGERS: 'vba.update_vba_managers',
  },
}
