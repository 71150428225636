import OVERSEER from 'Services/NewUserPermissionsService'

const MODEL_NAME = 'Vba'


export default class VbaPolicy {
  _record = undefined

  constructor (record) {
    this._record = record

    this._canUpdate = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'vba.update')
    this._canViewDebugPages = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'vba.view_debug_pages')
    this._canUpdateVbaManagers = OVERSEER.queryPermissions(MODEL_NAME, this._record, 'vba.update_vba_managers')
  }

  get canUpdate () { return this._canUpdate.can }
  get canViewDebugPages () { return this._canViewDebugPages.can }
  get canUpdateVbaManagers () { return this._canUpdateVbaManagers.can }
}
